.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo {
    color: #000;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
}

.main {
    width: 1012px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.main img {
    width: 504px;
    height: auto;
}

.login {
    width: calc(100% - 504px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content h2 {
    color: #333;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 700;
}

.content p {
    color: #333;
    font-family: Poppins;
    font-size: 18px;
}

.inputbox {
    display: flex;
    width: 360px;
    padding: 18px 26px;
    align-items: flex-start;
    gap: 15px;
    border-radius: 30px;
    border: 1px solid #EEE;
    background: #FFF;
}

.inputbox input {
    color: #333;
    font-family: Poppins;
    font-size: 14px;
    border: none;
    outline: none;
}

.content button {
    display: flex;
    width: 360px;
    padding: 18px 26px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
    transition: all ease-in 0.6s;
}

button:hover {
    background-color: #222222;
}

.content a {
    color: #333;
    font-size: 14px;
    justify-content: center;
    display: flex;
    text-decoration: none;
    padding: 5px;
    width: 360px;
}