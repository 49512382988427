.container {
    padding: 27px 0px 15px 0px;
    display: flex;
    flex-direction: column;
}

.head {
    padding-left: 30px;
    position: relative;
}

.head h1 {
    color: var(--Gray-900, #191B1C);
    font-family: "Public Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 10px;
    /* 95.833% */
}

.head span {
    color: var(--Gray-600, #626C70);
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    display: flex;
    align-items: center;
}

.subhead {
    background-color: #FFF;
    padding: 23px 0px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.search {
    border-radius: 7px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    width: 222px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.search input {
    outline: none;
    border: none;
    color: #878790;
    font-size: 14px;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.2px;
}

.selectinput svg {
    width: 30px;
    height: 17px;
    margin-right: 13px;
}

.formselect svg {
    width: 17px;
    height: 17px;
    margin-right: 15px;
}

.content {
    width: 100%;
    background-color: #FFF;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-height: 480px;
    gap: 5px;
    padding: 0px 15px;
}

.subcontent {
    overflow-y: scroll;
    background-color: rgba(227, 227, 227, 0.30);
    height: 400px;
    padding: 12px 8px;
}

.subcontent::-webkit-scrollbar {
    width: 6px;
}

.subcontent::-webkit-scrollbar-track {
    background-color: rgb(216, 214, 214);
    border-radius: 50px;
}

.subcontent::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    border-radius: 50px;
}

.subcontent {
    scrollbar-width: thin;
}

.subcontent h5 {
    color: var(--Mid-Gray-Mid-Gray-2, #6E7C87);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}

.list {
    width: 95%;
    flex-direction: column;
    gap: 8px;
    display: flex;
    margin: auto;
}

.name {
    border-radius: 6px;
    background: var(--black-white-white, #FFF);
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32), 0px 0px 1px 0px rgba(26, 32, 36, 0.32);
}

.num {
    padding: 5px 10px;
    margin-left: 7px;
    color: var(--Dark-Gray-Dark-Gray-2, #252C32);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: -0.084px;
    border-radius: 12px;
    background: var(--Light-Gray-Light-Gray-3, #E5E9EB);
}

.green {
    color: #FFF;
    font-size: 12px;
    padding: 3px 6px;
    background-color: green;
    font-weight: 600;
    margin-left: 8px;
    line-height: 16px;
    /* 133.333% */
    border-radius: 6px;
}

.red {
    background-color: #AD1519;
    color: #FFF;
    margin-left: 8px;
    padding: 3px 6px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}


/*  -------------add qa styling ------------- */
.maincontainer {
    width: 96%;
    margin: auto;
    margin-top: 52px;
    background-color: #ffffff;
}

.title {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 270px;
    font-size: 16px;
    cursor: pointer;
    justify-content: space-between;
    font-weight: 500;
    font-family: Inter;
    color: #6E7485;
}

.title span {
    display: flex;
    align-items: center;
    gap: 8px;
}

.activeTitle {
    color: #1D2026;
    border-bottom: 2px #007AFF solid;
}

.headerTitle {
    padding: 24px 40px;
    width: 93%;
    color: var(--Gray-900, #1D2026);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.24px;
    border-bottom: 1.5px solid #a9b0bc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputboxes {
    padding: 10px 25px 10px 6px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    align-items: center;
}

.inputboxes label {
    color: var(--Gray-900, #1D2026);
    font-family: Inter;
    font-size: 14px;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.14px;
}

.formselect {
    margin-top: 6px;
}

.formselect svg {
    width: 17px;
    height: 17px;
    margin-right: 15px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: auto;
    margin-top: 60px;
    align-items: center;
    margin-bottom: 25px;
}

.data {
    padding: 10px;
    margin-top: 25px;
    height: 200px;
}

.question {
    width: 100%;
    background-color: white;
}

.queslist {
    display: flex;
    flex-direction: column;
    width: 96%;
    margin: auto;
    margin-top: 16px;
}

.que {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    margin-top: 16px;
    background: var(--Gray-50, #F5F7FA);
    padding-bottom: 15px;
}

.tab {
    margin: 8px 21px;
    margin-top: 12px;
    background-color: white;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tab span {
    color: var(--Gray-900, #1D2026);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.14px;
    display: flex;
    align-items: center;
    gap: 14px;
}

.img {
    background: rgba(0, 122, 255, 0.10);
    padding: 8px 15px;
    color: #007AFF;
    cursor: pointer;
}

.addque {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px;
    border: none;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    background: rgba(0, 122, 255, 0.10);
    cursor: pointer;
    color: #007AFF;
}

.addque:hover {
    background-color: rgba(0, 122, 255, 0.20);
}

svg {
    cursor: pointer;
}

.buttonquiz {
    gap: 24px;
    margin-top: 50px;
    display: flex;
    width: 96%;
    align-items: center;
    justify-content: flex-end;
}

.row {
    width: 96%;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
}

.column {
    height: fit-content;
    padding: 0;
    margin: 0;
}

.columnhead {
    width: 100%;
    height: 51px;
    color: #FFF;
    font-size: 18px;
    background: var(--black-100, #1C1C1C);
    letter-spacing: -0.18px;
    text-transform: uppercase;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.columncontent {
    padding: 16px 24px;
    color: var(--Gray-900, #1D2026);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.line {
    height: 1px;
    width: 300px;
    background-color: #AD1519;
}

.end {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    color: #AD1519;
    text-transform: uppercase;
    margin: 40px 0;
}