.container{
    display: flex;
    gap: 0;
    justify-content: center;
    padding: 0;
    width: 100%;
    margin: 0;
    height: calc(100vh - 60px);
    margin-top: 60px;
}
.left{
    width: 250px;
    background-color: #000;
    color: white;
}
.right{
    background-color: rgba(227, 227, 227, 0.30);
    width: calc(100% - 250px);
    overflow-y: auto;
    overflow-x: hidden;
}
.menu{
    width: 90%;
    position: relative;
    margin: auto;
    color: rgba(255, 255, 255, 0.56);
    border-radius:5px;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
align-items: center;
display: flex;
margin-top: 10px;
cursor: pointer;
}
.menuActive{
    color: white;
    background: rgba(255, 255, 255, 0.18);
}
.menu:hover{
    color: white;
    background: rgba(255, 255, 255, 0.15);
}
.menu svg {
    stroke: white;
    padding: 16px 12px;
    stroke-opacity: 0.56;
}
svg{
    cursor: pointer;
}
.menuActive svg, .menu:hover svg{
    stroke: white;
    stroke-opacity: 1;
}
.subpaths{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    align-items: flex-end;
}
.subpaths div{
    width: 75%;
    font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px;
    margin: 3px 0px;
    color: rgba(255, 255, 255, 0.56);
    border-radius: 6px;
    padding: 10px 0;
    cursor: pointer;
    padding-left: 20px;
}
.subpaths div:hover{
background: rgba(255, 255, 255, 0.20);
color: white;
}
.submenu{
    background: rgba(255, 255, 255, 0.25);
color: white !important;
}