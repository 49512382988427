.mathquill4quill-mathquill-input {
  border: 1px solid #ccc;
  font-size: 13px;
  min-height: 26px;
  margin: 0;
  padding: 3px 5px;
  width: 170px;
}

.mathquill4quill-operator-button {
  margin: 5px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-color: #000;
  border-radius: 7px;
  border-width: 2px;
}

.mathquill4quill-operator-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mathquill4quill-history-button {
  overflow: hidden;
  margin: 5px;
  width: 270px;
  height: 65px;
  min-height: 60px;
  background-color: #fff;
  border-color: #000;
  border-radius: 7px;
  border-width: 2px;
  cursor: pointer;
  transition: background-color 0.3s linear;
  opacity: 1;
}

.mathquill4quill-history-button:hover {
  background-color: rgb(239, 240, 241);
  opacity: 0.7;
}

.mathquill4quill-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 150px;
  overflow: auto;
}

.mathquill4quill-history-container-with-delete-button {
  width: 318px;
}

.mathquill4quill-latex-input {
  visibility: hidden !important;
  padding: 0 !important;
  border: 0 !important;
  width: 0 !important;
}

.mathquill4quill-history-delete-button::after {
  display: inline-block;
  content: "\00d7";
  padding: 7px;
  cursor: pointer;
}

.mathquill4quill-history-inner-container {
  display: flex;
  justify-content: space-between;
}
