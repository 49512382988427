.header {
    width: 100%;
    height: 60px;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

.header h2 {
    color: #FFF;
    text-align: center;
    width: 250px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    text-transform: uppercase;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 34px;
}

.button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.56);
    border-radius: 5px;
    padding: 6px 10px;
    color: rgba(255, 255, 255, 0.56);
    margin-right: 10px;
    cursor: pointer;
}