.container {
    padding: 27px 23px 15px 30px;
    display: flex;
    flex-direction: column;
}

.head h1 {
    color: var(--Gray-900, #191B1C);
    font-family: "Public Sans";
    font-size: 24px;
    font-weight: 600;
    line-height: 10px;
    /* 95.833% */
}

.head span {
    color: var(--Gray-600, #626C70);
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    display: flex;
    align-items: center;
}

.main {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-top: 10px;
}

.left {
    width: 65%;
}

.subhead {
    background-color: #FFF;
    padding: 15px 18px;
    display: flex;
    align-items: center;
    gap: 14px;
}

.search {
    border-radius: 7px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    min-width: 222px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.add_topic {
    background: #1179EF;
    border-radius: 6px;
    padding: 12px 32px;
    border: none;
    outline: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.3s;
    cursor: pointer;
}

.search input {
    outline: none;
    border: none;
    color: #878790;
    font-size: 14px;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: 0.2px;
}

.header {
    padding: 12px 18px;
    text-transform: uppercase;
    color: var(--Gray-700, #4A5154);
    font-family: "Public Sans";
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
    /* 12px */
    text-transform: uppercase;
    display: flex;
    align-items: center;
}

.info {
    padding: 16px 18px;
    background-color: #FFF;
    color: var(--Gray-900, #191B1C);
    font-family: "Public Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    display: flex;
    text-transform: capitalize;
    align-items: center;
    border-bottom: 1px solid #E5E7E8;
}

.first {
    width: 12%;
}

.second {
    width: 34%;
}

.third {
    width: 18%;
    display: flex;
    align-items: center;
    gap: 25px;
}

.fourth {
    width: 10%;
}

.fifth {
    width: 15%;
}

.sixth {
    width: 22.5%;
}

.flexEnd {
    justify-content: center;
    display: flex;
}

.selectinput svg {
    width: 30px;
    height: 17px;
    margin-right: 13px;
}

.formselect svg {
    width: 17px;
    height: 17px;
    margin-right: 15px;
}

.third span {
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.inactive svg,
.active svg {
    margin-right: 6px;
}

.active {
    color: #0CBA09;
}

.inactive {
    color: #E84646;
}


.right {
    width: 35%;
    padding: 15px 18px;
    background-color: #FFF;
}

.right h5 {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.36px;
}

label {
    color: var(--Gray-900, #1D2026);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.14px;
    padding: 6px 0;
}

.input {
    display: flex;
    padding: 12px 16px 12px 18px;
    border-radius: 4px;
    border: 1px solid var(--Gray-100, #E9EAF0);
    background: var(--Gray-White, #FFF);
    margin-bottom: 21px;
    margin-top: 6px;
}

input {
    outline: none;
    border: none;
    width: 100%;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    color: #000;
    text-align: center;
    font-family: "Work Sans";
    margin-top: 21px;
}

.cancel {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.70);
    color: #000;
}

.cancel:hover {
    background: linear-gradient(0deg, rgba(61, 60, 60, 0.2) 0%, rgba(111, 110, 110, 0.2) 100%), rgba(255, 255, 255, 0.70);
}

.save {
    background: #000;
    color: white;
}

.save:hover {
    background: #373535;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    width: 100%;
    color: rgba(0, 0, 0, 0.7);
    font-family: "Public Sans";
    font-size: 14px;
    line-height: 21px;
    /* 150% */
}

.pagination div {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.onPage {
    padding: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 5px;
    color: #fff;
    border-radius: 190px;
    background: #000;
}



.editHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.editHead h2 {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.36px;
}

.borders {
    padding: 24px;
    color: var(--Gray-700, #4E5566);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.14px;
}