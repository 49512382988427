@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


body {
  padding: 0;
  margin: 0;
  width: 100vw;
  overflow: hidden;
  text-transform: capitalize;
  font-family: 'Poppins', sans-serif;
}

:root {
  --toastify-color-info: #3498db;
  --toastify-color-success: #00df80;
  --toastify-color-warning: #FFD21E;
  --toastify-color-error: #F04248;

  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

* {
  /* box-sizing: border-box; */

}

.quill {
  height: 100% !important;
  background-color: white !important;
  text-transform: none !important;
  font-family: unset !important;
}

.ql-tooltip {
  left: 0 !important;
}


.Toastify__toast--success {
  background-image: url("../public/assets/success_back.png");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: left;
}

.Toastify__toast--warning {
  background-image: url("../public/assets/warning_back.png");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: left;
}

.Toastify__toast--error {
  background-image: url("../public/assets/error_back.png");
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: left;
}


/* quill editor font family------------------------------- */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before {
  content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Ubuntu']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Ubuntu']::before {
  content: 'Ubuntu';
  font-family: 'Ubuntu';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Railway']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Railway']::before {
  content: 'Railway';
  font-family: 'Railway';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
  content: 'Roboto';
  font-family: 'Roboto';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='Serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='Serif']::before {
  content: 'Serif';
  font-family: 'Serif';
}